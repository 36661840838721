import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "オンライン診察機能に画面共有を実装した話",
  "date": "2021-07-30T08:35:08.000Z",
  "slug": "entry/2021/07/30/173508",
  "tags": ["medley"],
  "hero": "./2021_07_30.png",
  "heroAlt": "画面共有機能"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`事業本部 プロダクト開発室エンジニアの日下です。`}</p>
    <p><a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`オンライン診療・服薬指導・クラウド診療支援システム「CLINICS」`}</a>{`の、患者・医療機関に向けたアプリケーションの機能開発、開発基盤、インフラ周りを担当しております。`}</p>
    <p>{`今回 CLINICS が提供するオンライン診療機能に「画面共有機能」を追加しましたので、その背景・技術的な話をまとめます。`}</p>
    <h1>{`画面共有機能実装の背景`}</h1>
    <h2>{`CLINICS とオンライン診療`}</h2>
    <p>{`普段皆さんが病院にかかるとき、多くの場合は病院に行き、医師の診察を対面で受け、会計をして帰るといった流れになるかと思います。`}</p>
    <p>{`CLINICS のオンライン診療はこの流れをインターネットを通して提供するサービスです。`}</p>
    <p>{`※ オンライン診療は、一度、初診等で対面診療を受けた際に医師が可能と判断した場合、次回以降の診察において可能になります。また、現在は新型コロナウイルス感染症対策時限措置として、初診からオンライン診療を受けることが可能となっています。`}</p>
    <p>{`CLINICS を利用した場合、事前に予約した時間にスマホまたは PC で待機をする、医師の診察をビデオチャットで受け、会計はクレジットカードで行われるという流れとなっています。`}</p>
    <p>{`クラウド診療支援システムとしての CLINICS は `}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/clinics-6.html"
      }}>{`2016 年に「オンライン診療のためのシステム」としてローンチ`}</a>{`され、`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20180429-01.html"
      }}>{`2018 年にはクラウド型電子カルテ機能を`}</a>{`、`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/clinics-cloud.html"
      }}>{`2019 年には予約管理システム機能を`}</a>{`、`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/pharms-0903-1.html"
      }}>{`2020 年にはかかりつけ薬局支援システム Pharms との連携機能も追加し`}</a>{`、患者向けアプリからオンライン服薬指導をシームレスに受けることができるようになりました。`}</p>
    <p>{`プロダクト開発室ではこれらオンライン診療機能・電子カルテ機能・予約管理機能・連携機能の改善を日々行っています。`}</p>
    <h2>{`画面共有機能の需要の高まり、実装の決定`}</h2>
    <p>{`このように CLINICS の改善を日々行なっている中、昨年から始まった`}<a parentName="p" {...{
        "href": "https://medley.life/diseases/5e621d26dd126037cfc60fc9/"
      }}>{`新型コロナウイルス感染症（COVID-19）`}</a>{`の流行に伴った需要の増加により、オンライン診療の件数が急増しました。`}</p>
    <p>{`CLINICS も数多くの医療機関にご利用いただく中で、オンライン診療に関わるさまざまなご要望をいただくようになりました。その中でも特に多かったものが、今回紹介する画面共有機能です。`}</p>
    <p>{`対面での診察の際に医師が検査結果などを患者に見せながら説明するように、オンラインで診察する場合でも資料をリアルタイムで共有しながら説明ができるようになれば、今まで以上にオンラインでも質の高い診察を行えるようになります。`}</p>
    <p>{`こういったユースケース、要望などを検討した結果、CLINICS を利用するすべての医療機関及び患者にとって大きな恩恵が見込まれたため、オンライン診察（ビデオチャット）中に医師の PC 画面をリアルタイムで患者に共有する機能として実装をすることにしました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210729/20210729115806.png",
      "alt": "20210729115806.png"
    }}></img>
    <h1>{`画面共有機能の実装`}</h1>
    <p>{`画面共有をする医師側向けのコードでどういった実装方法があるのか、大まかな流れをまとめます。`}</p>
    <p>{`※ 以下に記載しているコードは説明のための疑似コードですので、このままでは動作しないことにご注意ください。また、医師側の実装例を掲載しているため、患者側（画面共有を受ける側）の実装は別途必要になります。`}</p>
    <h2>{`オンライン診察開始までの処理`}</h2>
    <p>{`オンライン診察を開始するには医師側のマイクとカメラで取得した情報を患者側に送付する必要があります。ここではそこまでの実装の流れを見ていきます。`}</p>
    <h3>{`カメラ・マイクのストリームの取得`}</h3>
    <p>{`オンライン診察開始時点で医師側のマイク・カメラの情報を共有するため、まずはそれらのストリームを取得する必要があります。こういったメディアコンテンツのストリームを司るインターフェイスとして `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/MediaStream"
      }}>{`MediaStream`}</a>{` が定義されています。`}</p>
    <p>{`マイク・カメラの MediaStream は、例えば `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia"
      }}>{`MediaDevices.getUserMedia()`}</a>{` を利用して取得できます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` userMediaStream`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getUserMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  video`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  audio`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`SkyWay 経由でオンライン診察を始める`}</h3>
    <p>{`WebRTC で P2P のビデオチャットを利用するためには、初期の接続のための処理及び接続の維持などの処理を行う必要があります。弊社ではこのあたりの処理を WebRTC SaaS の `}<a parentName="p" {...{
        "href": "https://webrtc.ecl.ntt.com/"
      }}>{`SkyWay`}</a>{` 及びその SDK を利用することで簡略化しています。`}</p>
    <p>{`オンライン診察開始時には、先程取得した医師側のマイク・カメラの MediaStream を SkyWay の SDK に渡すことで、一対一でのリアルタイムビデオチャットを実現できます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Peer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` MediaConnection `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"skyway-js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` peer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Peer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"your-api-key"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 事前に患者と共有しておいた peer id に対して call メソッドと MediaStream を渡すことで診察を開始できる。`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` mediaConnection`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaConnection `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`peer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`call`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"shared-peer-id"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  userMediaStream
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 注: 患者側は送付された処理をハンドリングする機能を実装する必要がある`}</span></code></pre></div>
    <p>{`ここまでがオンライン診察を開始するまでの処理です。`}</p>
    <p>{`※ 詳細は SkyWay 公式の`}<a parentName="p" {...{
        "href": "https://webrtc.ecl.ntt.com/documents/javascript-sdk.html#%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%83%AA%E3%82%A2%E3%83%AB"
      }}>{`チュートリアル`}</a>{`などを参照ください。`}</p>
    <h2>{`画面共有の処理`}</h2>
    <p>{`ここまでで患者に対して医師側のカメラ・マイクで取得された映像・音声が表示されている状態のため、これを切り替える処理が必要になります。今回は現在接続に利用している MediaStream を、画面共有用の MediaStream に入れ替えることで実現しました。`}</p>
    <h3>{`画面の MediaStream の取得`}</h3>
    <p>{`まずは共有する画面の MediaStream を取得する必要があります。これは `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia"
      }}>{`MediaDevices.getDisplayMedia()`}</a>{` を使うことで実現できます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` displayStream`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDisplayMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` video`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`画面共有用の MediaStream を作る`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`getDisplayMedia()`}</code>{` から共有する画面の MediaStream を取得できるものの、そのまま利用するとマイクの音声が入りません。`}</p>
    <p>{`これは `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getDisplayMedia()`}</code>{` から取れる MediaStream にマイクの音声が含まれていないことが原因なので、必要な画像・音声の組み合わせを持った画面共有用の MediaStream を作成することで対処ができます。`}</p>
    <h4>{`MediaStreamTrack を組みあわせて画面共有用の MediaStream を作る`}</h4>
    <p>{`画面共有用の MediaStream を作成する前にまず、MediaStreamTrack と MediaStream の関係を理解する必要があります。`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/MediaStreamTrack"
      }}>{`MediaStreamTrack`}</a>{` はストリームに含まれる一つのメディアトラックを表現するものです。
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/MediaStreamTrack/kind"
      }}>{`kind`}</a>{` という読み取り専用プロパティがあり、オーディオトラックであれば `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"audio"`}</code>{` が、ビデオトラックであれば `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"video"`}</code>{` が設定されています。`}</p>
    <p>{`また、 MediaStream は複数の MediaStreamTrack から成り、オーディオトラック・ビデオトラックを取り出すメソッドがそれぞれ `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/MediaStream/getAudioTracks"
      }}>{`MediaStream.getAudioTracks()`}</a>{`・`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/MediaStream/getVideoTracks"
      }}>{`MediaStream.getVideoTracks()`}</a>{` として実装されています。`}</p>
    <p>{`これらを組み合わせることで、マイクと画面の MediaStreamTrack を持つ MediaStream を作ることができ、これを SkyWay の SDK に渡すことで、画面共有を実現できます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`displayVideoTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStreamTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` displayStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getVideoTracks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 画面共有の音声はマイクの音声を利用したいので、userMediaStream から audioTrack を取り出しておく`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`userAudioTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStreamTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` userMediaStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getAudioTracks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 画面共有するための MediaStream を作成する(画面の videoTrack、マイクの audioTrack を持つ MediaStream を作る)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` sharingMediaStream`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MediaStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  displayVideoTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  userAudioTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`MediaStream の入れ替え`}</h3>
    <p>{`最後に画面共有状態への切り替えです。マイク・カメラが共有されている状態からの切り替えにはいくつかの方法が考えられます。`}</p>
    <p>{`例えば、多重化であれば MediaConnection（ Skyway の SDK の単位で、「接続先 Peer へのメディアチャネル接続」を管理する）の多重化、MediaStream の多重化、MediaStreamTrack の多重化がそれぞれ考えられます。これらの方法はマイク・カメラの切り替え時のチラつき抑制など実装上の選択肢が増えるメリットがある一方で、通信量が多くなってしまう点がデメリットと言えます。`}</p>
    <p>{`今回は多重化をせずに既存の MediaStream を切り替える実装を紹介します。この方法のメリットは、多重化に比べると通信量が少なく、またすでに MediaStream が一つである前提で作られている場合は、画面共有を受ける側の実装の変更が不要という点です。`}</p>
    <p>{`この方法は、 SkyWay の SDK であれば MediaConnection の `}<a parentName="p" {...{
        "href": "https://webrtc.ecl.ntt.com/api-reference/javascript.html#methods-2"
      }}>{`replaceStream というメソッド`}</a>{`に対して新しい MediaStream を渡すことで実現ができます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// 画面共有用の MediaStream を渡すことで、画面共有を開始する`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// MediaConnection は先程 \`peer.call\` した際の返り値として取れているため、それを利用する`}</span>{`
mediaConnection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`replaceStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sharingMediaStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`実装前に懸念していたマイク・カメラの切り替え時のチラつきなども気になるほどはなく、実用に足るような品質を保つことができることを確認しています。`}</p>
    <h2>{`実装の全体概要`}</h2>
    <p>{`以上の流れを実装すると、次のようなコードになります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Peer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` MediaConnection `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"skyway-js"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/** 医師側のマイク・カメラを共有してオンライン診察開始するところまで **/`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// getUserMedia()でカメラ・マイクのストリームを取得`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` userMediaStream`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getUserMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  video`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  audio`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Skyway sdk の初期化処理`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` peer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Peer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"your-api-key"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// オンライン診察の開始`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` mediaConnection`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaConnection `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`peer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`call`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"peerId"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` userMediaStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/** 画面共有を開始する処理 **/`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 画面共有する画面の stream を取る`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` displayStream`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` navigator`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`mediaDevices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getDisplayMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` video`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`displayVideoTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStreamTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` displayStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getVideoTracks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 画面共有の音声はマイクの音声を利用したいので、userMediaStream から audioTrack を取り出しておく`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`userAudioTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStreamTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` userMediaStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getAudioTracks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 画面共有するための MediaStream を作成する(画面の videoTrack、マイクの audioTrack を持つ MediaStream を作る)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` sharingStream`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStream `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MediaStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  displayVideoTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  userAudioTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 画面共有用の MediaStream を渡すことで、画面共有を開始する`}</span>{`
mediaConnection`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`replaceStream`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sharingStream`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`開発中に遭遇した問題への対応`}</h2>
    <h3>{`スリープモード・共有を停止ボタンを押したときの対応`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.google.com/intl/ja_jp/chrome/"
      }}>{`Google Chrome`}</a>{` で画面共有の際に表示される「共有を停止」ボタンを押下したり、PC をスリープモードにすると、画面の MediaStreamTrack が途切れてしまいます。`}</p>
    <p>{`これは該当の MediaStreamTrack に `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"ended"`}</code>{` のイベントリスナを登録しておくことでハンドリングできます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`displayVideoTrack`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ended"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` handleEndedEvent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` once`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3>{`TypeScript の型の対応`}</h3>
    <p>{`現状 TypeScript の型が `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getDisplayMedia()`}</code>{` に対応していなかったため、今回は実装の参考にしている `}<a parentName="p" {...{
        "href": "https://github.com/skyway/skyway-conf/blob/5c5531b79d0cd9407c071911498dedd695414039/src/conf/utils/types.ts#L98-L102"
      }}>{`skyway-conf で利用されている型`}</a>{`を流用する形で対応をしました。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`declare`}</span>{` global `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MediaDevices`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getDisplayMedia`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`constraints`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` MediaStreamConstraints`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Promise`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`MediaStream`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`これは根本的には TypeScript の dom.d.ts に型定義が入っていないことが起因していますが、`}<a parentName="p" {...{
        "href": "https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1029"
      }}>{`TypeScript4.4 で対応がされるようです`}</a>{`。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`昨今の状況により、オンライン診察のニーズが高まり、画面共有機能の重要性が高まりました。
診察中の画面共有機能は以下の api を組み合わせることで実現することができます。`}</p>
    <ul>
      <li parentName="ul">{`PC 画面の MediaStream は `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`getDisplayMedia()`}</code>{` を使うことで取得`}</li>
      <li parentName="ul">{`MediaStream に含める音声・画像ストリームを変更したい場合は MediaStreamTrack の組み合わせを変えることで作成`}</li>
      <li parentName="ul">{`接続中の MediaStream の変更は SkyWay の SDK の `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`MediaConnection.replaceStream()`}</code>{` を使う`}</li>
    </ul>
    <h1>{`最後に`}</h1>
    <p>{`CLINICS では本稿で紹介した画面共有などの新規機能の導入や日々の改善を通じて、医療機関・患者双方に支持されるプロダクトを目指し開発を行っています。興味を持たれたエンジニアの方がいらっしゃいましたらぜひ`}<a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/engineer1.html"
      }}>{`こちら`}</a>{`にご連絡いただければと思います。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      